import React, { useState, useEffect } from 'react';
import '../style/Navbar.css';
import sortIcon from '../icons/sort.png';
import plusIcon from '../icons/create.png';

const Navbar = ({ setSearchResults, resetSearch, setSortOption, sortOption, setCreateVisible }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [dropdownVisible, setDropdownVisible] = useState(false);

    useEffect(() => {
        if (searchTerm.trim() === '') {
            resetSearch();
            return;
        }

        const timeoutId = setTimeout(() => {
            fetch(`https://predict-the-future.com/search?q=${encodeURIComponent(searchTerm)}&sort=${encodeURIComponent(sortOption)}`)
                .then(res => res.json())
                .then(data => {
                    setSearchResults(data);
                })
                .catch(console.error('Search failed:') );
        }, 300);

        return () => clearTimeout(timeoutId);
    }, [searchTerm, setSearchResults, resetSearch, sortOption]);

    const toggleCreate = () => {
        setCreateVisible(prev => !prev);
    };

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const handleSortOption = (option) => {
        setSortOption(option);
        setDropdownVisible(false);
    };

    return (
        <nav className="navbar">
            <div className="navbar-items">
                <div className="icon-sort" onClick={toggleDropdown}>
                    <img alt="Sort Icon" className="sort-icon" src={sortIcon} />
                </div>
                <div className="input-search">
                    <input
                        type="text"
                        placeholder="Look for predictions & user"
                        className="search-input"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
            </div>
            <div className="icon-create">
                <img alt="Create Icon" className="create-icon" src={plusIcon} onClick={toggleCreate}/>
            </div>
            {dropdownVisible && (
                <div className="dropdown-menu show">
                    <div className="dropdown-item" onClick={() => handleSortOption('popularity')}>Popularity</div>
                    <div className="dropdown-item" onClick={() => handleSortOption('upvotes')}>Upvotes</div>
                    <div className="dropdown-item" onClick={() => handleSortOption('downvotes')}>Downvotes</div>
                    <div className="dropdown-item" onClick={() => handleSortOption('newest')}>Newest (Default)</div>
                    <div className="dropdown-item" onClick={() => handleSortOption('oldest')}>Oldest</div>
                    <div className="dropdown-item" onClick={() => handleSortOption('today')}>Nearest Events</div>
                </div>
            )}
        </nav>
    );    
};

export default Navbar;