import React, { useEffect, useState } from 'react';
import '../style/Card.css';
import upvoteIcon from '../icons/arrow_up.png';
import downvoteIcon from '../icons/arrow_down.png';

var apiKey = '!aDB53DSs!f74SDxa5!23jkFgSe!24fDsx!5edgEr56gxc';

const Card = ({ id, username, title, upvotes, downvotes, uploadDate, eventDate }) => {
    const [voteCount, setVoteCount] = useState(upvotes);
    const [downvoteCount, setDownvoteCount] = useState(downvotes);
    const [hasUpvoted, setHasUpvoted] = useState(false);
    const [hasDownvoted, setHasDownvoted] = useState(false);

    useEffect(() => {
        const upvoteStatus = localStorage.getItem(`upvoted_${id}`);
        const downvoteStatus = localStorage.getItem(`downvoted_${id}`);

        setHasUpvoted(upvoteStatus === 'true');
        setHasDownvoted(downvoteStatus === 'true');
    }, [id]);

    const handleUpvote = async () => {
        try {
            if (hasUpvoted) {
                const res = await fetch(`https://predict-the-future.com/prediction/${id}/remove-upvote`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': apiKey
                    },
                });
                if (!res.ok) throw new Error('Error removing upvote');

                setVoteCount((prev) => prev - 1);
                setHasUpvoted(false);
                localStorage.setItem(`upvoted_${id}`, 'false');
            } else {
                const res = await fetch(`https://predict-the-future.com/prediction/${id}/upvote`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': apiKey
                    },
                });
                if (!res.ok) throw new Error('Error adding upvote');

                setVoteCount((prev) => prev + 1);
                setHasUpvoted(true);
                localStorage.setItem(`upvoted_${id}`, 'true');

                if (hasDownvoted) {
                    await handleDownvote(false);
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleDownvote = async (shouldUpdate = true) => {
        try {
            if (hasDownvoted) {
                const res = await fetch(`https://predict-the-future.com/prediction/${id}/remove-downvote`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': apiKey
                    },
                });
                if (!res.ok) throw new Error('Error removing downvote');

                setDownvoteCount((prev) => prev - 1);
                setHasDownvoted(false);
                localStorage.setItem(`downvoted_${id}`, 'false');
            } else {
                const res = await fetch(`https://predict-the-future.com/prediction/${id}/downvote`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': apiKey
                    },
                });
                if (!res.ok) throw new Error('Error adding downvote');

                setDownvoteCount((prev) => prev + 1);
                setHasDownvoted(true);
                localStorage.setItem(`downvoted_${id}`, 'true');

                if (hasUpvoted) {
                    await handleUpvote();
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <article className="card">
            <header className="card-header">
                <span className="card-username">@{username}</span>
                <h1 className="card-title">{title}</h1>
                <hr />
            </header>
            <section className="card-content">
                <div className="card-votes">
                    <div className="vote upvote" onClick={handleUpvote}>
                        <img alt="Upvote" className="vote-icon" id="upvote" src={upvoteIcon} />
                        <span className="vote-count" style={{ color: hasUpvoted ? '#58D82D' : 'white' }}>{voteCount}</span>
                    </div>
                    <div className="vote downvote" onClick={handleDownvote}>
                        <img alt="Downvote" className="vote-icon" id="downvote" src={downvoteIcon} />
                        <span className="vote-count" style={{ color: hasDownvoted ? '#D02638' : 'white' }}>{downvoteCount}</span>
                    </div>
                </div>
                <div className="card-meta">
                    <div className="meta-item">
                        <span className="meta-tag" id="upload-tag">UPLOAD</span>
                        <time className="meta-date" id="upload-date">{uploadDate}</time>
                    </div>
                    <div className="meta-item">
                        <span className="meta-tag" id="event-tag">EVENT</span>
                        <time className="meta-date" id="event-date">{eventDate}</time>
                    </div>
                </div>
            </section>
        </article>
    );
};

export default Card;