import React, { useEffect, useState, useCallback } from 'react';
import './style/App.css';
import Card from './components/Card';
import Navbar from './components/Navbar';
import Create from './components/Create';

Card.defaultProps = {
  username: 'anonymous',
  title: "undefined",
  upvotes: 0,
  downvotes: 0,
  uploadDate: 'N/A',
  eventDate: 'N/A',
};

function App() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [sortOption, setSortOption] = useState('newest');
  const [createVisible, setCreateVisible] = useState(false);

  const fetchMoreData = useCallback(() => {
    if (!hasMore || loading) return;

    setLoading(true);

    fetch(`https://predict-the-future.com/prediction?page=${page}&sort=${sortOption}`)
      .then(res => res.json())
      .then(newData => {
        if (newData.length === 0) {
          setHasMore(false);
        } else {
          setData(prevData => {
            const uniqueData = newData.filter(item =>
              !prevData.some(prevItem => prevItem.ID === item.ID)
            );
            return [...prevData, ...uniqueData];
          });
          setPage(prevPage => prevPage + 1);
        }
      })
      .catch(err => {
        // Debug console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [page, hasMore, loading, sortOption]);

  const resetSearch = useCallback(() => {
    setSearchResults([]);
    setPage(1);
    setHasMore(true);
    setData([]);
  }, []);

  const handleScroll = useCallback(() => {
    if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 100) {
      fetchMoreData();
    }
  }, [fetchMoreData]);

  useEffect(() => {
    if (data.length === 0) {
      fetchMoreData();
    }

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [fetchMoreData, handleScroll, data.length]);

  useEffect(() => {
    resetSearch();
  }, [sortOption, resetSearch]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    let test = date.toLocaleDateString();
    let splitted = test.split('.');
    if (splitted[0] < 10) {
      splitted[0] = "0" + splitted[0];
    }
    if (splitted[1] < 10) {
      splitted[1] = "0" + splitted[1];
    }
    return `${splitted[2]}-${splitted[1]}-${splitted[0]}`
  };

  const handleCreateSuccess = () => {
    setCreateVisible(false);
    fetchMoreData();
  };

  return (
    <div className="App">
      <Navbar 
        setSearchResults={setSearchResults} 
        resetSearch={resetSearch} 
        setSortOption={setSortOption} 
        setCreateVisible={setCreateVisible}
      />
      {createVisible && <Create onSuccess={handleCreateSuccess} />} {}
      <div className="card-container">
        {searchResults.length > 0 ? (
          searchResults.map((item) => (
            <Card
              key={item.ID}
              id={item.ID}
              username={item.Username}
              title={item.Title}
              upvotes={item.Upvotes}
              downvotes={item.Downvotes}
              uploadDate={formatDate(item.UploadDate)}
              eventDate={formatDate(item.EventDate)}
            />
          ))
        ) : (
          data.map((item) => (
            <Card
              key={item.ID}
              id={item.ID}
              username={item.Username}
              title={item.Title}
              upvotes={item.Upvotes}
              downvotes={item.Downvotes}
              uploadDate={formatDate(item.UploadDate)}
              eventDate={formatDate(item.EventDate)}
            />
          ))
        )}
      </div>

      {!hasMore && searchResults.length === 0 && (
        <>
          <br />
          <p style={{ textAlign: 'center', color: '#fff', fontFamily: 'Poppins' }}>
            You have explored all predictions.
          </p>
        </>
      )}
    </div>
  );
}

export default App;