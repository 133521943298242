import React, { useState } from 'react';
import '../style/Create.css';

var apiKey = '!aDB53DSs!f74SDxa5!23jkFgSe!24fDsx!5edgEr56gxc';

function Create({ onSuccess }) {
    const [username, setUsername] = useState('');
    const [title, setTitle] = useState('');
    const [eventDate, setEventDate] = useState('');
    const uploadDate = new Date().toISOString();
    const currentTime = new Date().toLocaleTimeString('it-IT', { hour12: false });

    const handleUsername = (event) => {
        const { value } = event.target;
        const validRegex = /^[A-Za-z_0-9]*$/;

        if (value.length > 16) {
            setUsername(value.slice(0, 16));
        } else if (validRegex.test(value)) {
            setUsername(value);
        } else {
            setUsername(value.replace(/[^A-Za-z_0-9]/g, ''));
        }
    };

    const handlePrediction = (event) => {
        const { value } = event.target;
        const validRegex = /^[A-Za-z0-9\s?!.,]*$/;

        if (value.length > 112) {
            setTitle(value.slice(0, 112));
        } else if (validRegex.test(value)) {
            setTitle(value);
        } else {
            setTitle(value.replace(/[^A-Za-z0-9\s?!.,]/g, ''));
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        let finalUsername = username;
        let uploaddate = uploadDate;
        let eventdate = eventDate;

        uploaddate = uploaddate.split("T")[0];
        eventdate = eventdate.split("T")[0];
    
        if (username.length < 2) {
            finalUsername = 'anonymous';
        }
    
        const selectedDate = new Date(eventDate);
        const today = new Date();
        
        selectedDate.setHours(0, 0, 0, 0);
        today.setHours(0, 0, 0, 0);
    
        if (selectedDate <= today) {
            alert("Please select a date that is at least tomorrow.");
            return; 
        }
    
        const predictionData = {
            Username: finalUsername,
            Title: title,
            Upvotes: 0,
            Downvotes: 0,
            UploadDate: uploaddate,
            EventDate: eventdate,
            Time: currentTime,
        };
    
        try {
            const response = await fetch('https://predict-the-future.com/prediction', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': apiKey
                },
                body: JSON.stringify(predictionData),
            });
    
            const result = await response.json();
            if (response.ok) {
                console.log('Prediction added:', result);
                onSuccess();
                window.location.reload();
            } else {
                // Debug console.error('Error adding prediction:', result);
            }
        } catch (error) {
            console.error('Error adding prediction:' /*error*/);
        }
    };

    return (
        <div className="create">
            <div className="create-container">
                <h1>Add your prediction</h1>
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        placeholder="Your username (optional)"
                        className="username-input"
                        value={username}
                        onChange={handleUsername}
                        maxLength={24}
                    />
                    <input
                        type="text"
                        placeholder="Your prediction"
                        className="prediction-input"
                        value={title}
                        onChange={handlePrediction}
                        maxLength={90}
                        required
                    />
                    <div className="create-submit">
                        <input
                            type="date"
                            className="date-input"
                            placeholder="Select a date"
                            value={eventDate}
                            onChange={(e) => setEventDate(e.target.value)}
                            required
                        />
                        <button type="submit">Submit</button>
                    </div>
                    <h2 className="date-information">This is the event date for your prediction.</h2>
                </form>
            </div>
        </div>
    );
}

export default Create;